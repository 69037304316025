import React from "react";
import Layout from "../components/UI/Layout/Layout"
import SignsOfCompetitiveResearchDoc from "../content/markdown-pages/signs-of-competitive-research.mdx"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

export default() =>  {
  return (
    <Layout>
    <Container>
        <Tabs activeKey="current" id="publications-tabs">
            <Tab title="Current Report - 2019" eventKey="current">
              <Container className={["rounded","shadow","p-2","mdx-div-container"]}>
                <SignsOfCompetitiveResearchDoc></SignsOfCompetitiveResearchDoc>
              </Container>
                
            </Tab>
          <Tab title="Previous Reports" eventKey="previous-reports">
                  <p>
                    This section will list reports by year and other documents of interest.

                  </p>
            </Tab>
          <Tab title="Research library" eventKey="library">
            <p>
              This section will aggregate content of interest related to the <em>Signs of Competitiveness</em> report.
            </p>
          </Tab>
        </Tabs>
    </Container>
    </Layout>
      );
}
