import React from "react";
import CorrelationPlotComponent from "../components/Plots/CorrelationPlotComponent/CorrelationPlotComponent.js"
import Layout from "../components/UI/Layout/Layout"
import GenericPlot from "../components/Plots/GenericPlot.js"
import YearAvailabilityData from "../data/plots/capabilities/plot_year_coverage.json"
import Table from "react-bootstrap/Table"
import DataTableRedux from '../components/data/DataTableRedux.js'
import DataTable from 'react-data-table-component';
import _ from "lodash"

class TestingCorrelationPlotComponent extends React.Component{
    state={
      query:{},
      defaultQuery: {
  year: 2007,
    country: "PRY",
      sectors: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      }
    }
     render= () =>  {
      return (<>
         <CorrelationPlotComponent defaultQuery= {this.state.defaultQuery} 
       onQueryChanged={(query)=>{
              console.log("Parent onquerychanged called with",query);
              this.setState({query:query})}}></CorrelationPlotComponent>
        <div>
          {_.reduce({...this.state.query},(a,v,k,n)=> _.toString(a+"-"+k+":"+v))}
          <DataTableRedux query={!_.isEqual({},this.state.query)?this.state.query:this.state.defaultQuery} plotKey="CorrelationPlotComponent"></DataTableRedux>
        </div>
        </>
      )

          }

}
export default TestingCorrelationPlotComponent
