import React from 'react'
import Table from 'react-bootstrap/Table'

export default () => {return (
<Table bordered hover>
    <thead>
        <tr>
            <th>Dataset</th>
            <th>Filename</th>
            <th>Use</th>
            <th>Download</th>
        </tr>
    </thead>
    <tbody>
        <tr>

            <td>IMF</td>

            <td>EQ_SITC4Plus_06-27-2019_13-27-27-71_timeSeries.csv</td>
            <td>Both the Atlas and the Signs of Competitiveness paper use this dataset</td>
            <td><a href="http://www.tldr.mx/datasets/EQ_SITC4Plus_06-27-2019_13-27-27-71_timeSeries.zip">Here</a></td>
      </tr>
            <tr>
                <td>Harvard's CID v17</td>
                <td>country_partner*.tab</td>
                <td>Only the Atlas uses this dataset</td>
                <td><a href="http://www.tldr.mx/datasets/harvard-17/">Here</a></td>
            </tr>
            <tr>
                <td>Harvard's CID v16</td>
                <td>16.zip</td>
                <td>Only the Signs of Competitiveness research uses this dataset</td>
                <td><a href="http://www.tldr.mx/datasets/harvard-16/16.zip">Here</a></td>
            </tr>
            <tr>
                <td>OAS Capabilities</td>
                <td>capabilities.zip</td>
                <td>Both the Atlas and the Research use this dataset</td>
                <td><a href="http://www.tldr.mx/datasets/capabilities.zip">Here</a></td>
            </tr>
  </tbody>
</Table>

)
}