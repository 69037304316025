// import Griddle from 'griddle-react';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as sels from "../../state/selectors.js"
import * as actions from "../../state/actions.js"
import _ from 'lodash'
import DataTable from 'react-data-table-component';
import { CSVLink} from "react-csv";

export class DataTableRedux extends Component {
    componentDidUpdate(prevProps, prevState) {
        console.log("DataTable did update prevprops:",prevProps)
        console.log("DataTable did update currentprops:",this.props)
        console.log("prevprops=false,this.props.curplot has data and this.props.underRequest== true to request a new table")
        if(!_.isEqual(prevProps.curplot.requestQuery,this.props.curplot.requestQuery) && this.props.underRequest){
            //Queries arent the same and there is no table data under this query
            let existingDataTableIdx = sels.getDataTablePlotIndexWithDataByQuery(this.props.plotArray, this.props.curplot.requestQuery)
            if (existingDataTableIdx!==-1){
                this.props.requestTableFromCache(this.props.plotKey,existingDataTableIdx)  
                return
            }

            console.log("DOING SERVER REQUEST")
            console.log("Because this:",this.props.curplot.requestQuery, "was not found here with data",this.props.plotArray)
            this.props.requestTableFromServer(
                                        this.props.plotKey,
                                        this.props.query
                                        )
            return 
        }
        console.log("NONO REQUEST")
    }
    titleFromQuery () {
        let r=""
        let dlbutton = "";
        let sectors="";
        if(!this.props.underRequest && this.props.dataTable)
        {
            dlbutton=<CSVLink data={this.props.dataTable}>Download</CSVLink>
        }
        sectors = <div className={["text-wrap"]}><small>{this.props.queryForDisplay.sectors}</small></div>
        r = <>
        <div>{`All data for ${this.props.queryForDisplay.country} ${this.props.queryForDisplay.year} for sectors:`}<br></br>
        {sectors}
        {dlbutton}
        </div>
        </>
        
        return r;

    }
    
    render() {
        let tab = null;
        return (
            <DataTable data={this.props.dataTable===false?[]:this.props.dataTable}
                columns={this.props.dataTableHeaders}
                title={this.titleFromQuery()}
                progressPending={this.props.underRequest}
                ></DataTable>
        )
    }
    componentDidMount() {
        if(this.props.curplot===false){
            this.props.requestTable(this.props.plotKey,this.props.query)
        }
    }
    
}

const mapStateToProps = (state,myProps) => {
    const cplot = sels.getPlotByQuery(state, myProps.plotKey, myProps.query)
    return {
    curplot:cplot,
    underRequest:sels.plotHasTableUnderRequest(cplot),
    dataTableHeaders: sels.getDataTableColumnsReactTableConformant(state,cplot),
    dataTable:sels.getDataTable(cplot),
    queryForDisplay:sels.queryForDisplay(state,myProps.query),
    plotArray:sels.getPlot(state,myProps.plotKey)
    }
}

const mapDispatchToProps = dispatch=>{
    return {
        requestTable : (plotKey,requestQuery)=>{
                                        dispatch(
                                            actions.requestTable(
                                                plotKey,
                                                requestQuery))
                                            },
        requestTableFromCache:(plotKey,plotIdx) => {
                                            dispatch(actions.requestTableFromCache(
                                                plotKey,
                                                plotIdx)
                                            )
                                            },
        requestTableFromServer:(plotKey,query) => {
            dispatch(
                actions.load_quality_vs_harvard_correlations_by_country_year_data_table(query)).then(
                            function(response){
                                dispatch(
                                    actions.receiveTable(
                                        plotKey,
                                        query,
                                        response.payload.data)
                                )}
                            )
                    }
        }
                            
    }


export default connect(mapStateToProps, mapDispatchToProps)(DataTableRedux)
