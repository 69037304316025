import * as at from "./actiontypes.js";
import { all_sectors, all_years_harv_imf, all_countries_harv_imf, country_code_name_map, sector_code_name_map, cors_variable_name_map} from "../GLOBALS.js"
import _ from 'lodash'
import * as sels from './selectors.js'
const updateObject = (oldo,newo) => {
  return {
          ...oldo,
          ...newo
        }
}

const initialState = {
    ocpuBaseUrl:"https://www.tldr.mx/ocpu/",
    selectedSectors:[0],
    availableYears:[],
    requestingYears:false,
    country_code_name_map:country_code_name_map,
    sector_code_name_map: sector_code_name_map,
    cors_variable_name_map:cors_variable_name_map,
    queryQueues:{
                // queue_id:[]
              },
    simpleLists:{
        "all_sectors":all_sectors,
        "all_years_harv_imf":all_years_harv_imf,
        "oas_countries_harv_imf":all_countries_harv_imf
    },
    simpleListsSelections: {
     // selectorId:{selectedItems:[],selectorId:0,listKey="STRINGID",valueKey="stringId"}
    },
    availableToSelect: {
      "all_sectors":all_sectors,
      "all_years_harv_imf":all_years_harv_imf,
      "oas_countries_harv_imf": [{ id: 0, name: "Paraguay", value: "PRY" }]
    },
    plots:{
      // "plotKey":[{
      //   requestQuery:{}, //In response to and as request for filtering
      //   plotData:{}
      //   dataTable:[] //The table in response to the requestQuery
      // }]
    }
    
};

const requestTable=(state,payload) => {
  const p = {}
  const pkey = payload.plotKey;
  const pquery = payload.requestQuery;
  
  //Plot array to object
  p[pkey]=sels.getPlot(state,pkey)
  p[pkey]=p[pkey]?p[pkey]:[]
  //Find out if the request is there already
  const actualPlotIdx = sels.getPlotIndexByQuery(p[pkey],pquery)
  if(actualPlotIdx!==-1){
    //actualPlot is the index of the plot with this query. 
    const actualPlot=updateObject({},p[pkey][actualPlotIdx])
    let ret0=sels.removeItem(p[pkey],actualPlotIdx)
    actualPlot.dataTable=[]
    ret0=sels.insertItem(ret0,actualPlotIdx,actualPlot)
    p[pkey]=ret0
  }
  else {
    //There is no query in the array that matches
    //we push a full object at the top
    // Yes, I realize this will also get the plot but, supposedly, 
    //we handle cache by query and data existance in the components
    const newPlot={
      requestQuery:{...pquery},
      plotData:{},
      dataTable:[]
    }
    let ret1=sels.insertItem(p[pkey],0,newPlot)
    p[pkey]=ret1
  }
  return (updateObject(state,{plots:p}))
}

const receiveTable = (state,payload) => {
  return updateTable(state,payload)
}

const updateTable = (state,payload) => {
  const pKey=payload.plotKey;
  const pQuery=payload.requestQuery;
  const data=payload.tableData;
  const retPlot=sels.getPlot(state,pKey)
  let wPlot = retPlot === false?[]:retPlot;
  let actualPlotIdx=sels.getPlotIndexByQuery(wPlot,pQuery)
  let actualPlot={}
  if(actualPlotIdx===-1){
    //Not found, inserting the whole thing
    actualPlot.requestQuery=pQuery;
    actualPlot.dataTable=data;
    actualPlot.plotData={}
    actualPlotIdx=0
    // console.log("Actual plot on not found",actualPlot)
  }
  else{
    // found, updating data
    actualPlot.dataTable=data;
    // console.log("Actual plot before updateobject",actualPlot)
    // console.log("wPlot on index",wPlot[actualPlotIdx])
    actualPlot = updateObject(wPlot[actualPlotIdx], actualPlot)
    wPlot = sels.removeItem(wPlot, actualPlotIdx)
    
  }
  // console.log("actualPlot",actualPlot)
  wPlot = sels.insertItem(wPlot, actualPlotIdx, actualPlot)
  // console.log("wPlot",wPlot)
  let ret= updateObject(state,{plots:updateObject(
                                    sels.getPlots(state),
                                    {[pKey]:wPlot}
                                  )
                              }
  )
  // console.log("Update table returning state:",ret)
  return {...ret}
}

const requestTableFromCache=(state, payload)=>{
  const plotWithTableData=sels.getPlotAt(state,payload.plotKey,payload.plotIndex)
  const plotArray=sels.getPlot(state,payload.plotKey)
  if(plotWithTableData===false){
    console.log("Requesttablefromcache: no plot at the array -> index pointed to by",payload)
    return state
  }
  const plotWithoutTableDataIdx = sels.getDataTablePlotIndexWithoutDataByQuery(plotArray,plotWithTableData.requestQuery);
  if(plotWithoutTableDataIdx===-1) {
    console.log("Table requested from cache but no original, tabledata empty request found")
    console.log("The plot has data for this query then, nothing to do")
    return state;
  }
if(payload.plotIndex===plotWithoutTableDataIdx){
  console.log("Requestad an index from cache that was found to have no data")
  console.log("Requested this plot, that should have data",sels.getPlotAt(state,payload.plotKey,"But at the same index we found this with no data",sels.getPlotAt(state,payload.plotKey)))
  return state;
}
//copy the stuff to the empty dataTable plot and return
  return updateTable(state,updateObject(
    payload,
    {dataTable: [...plotWithTableData.dataTable],
    requestQuery: {...plotWithTableData.requestQuery}}
  ))
}
const requestPlot=(state,payload) =>{
  const p = {}
  const pkey=payload.plotKey;
  const pquery=payload.requestQuery;
  const fullreq = {
    requestQuery: pquery,
    plotData: {}
  }
  // p[pkey]={requestQuery:pquery,
  //           plotData:{}}
  p[pkey]=sels.getPlot(state,pkey);
  if(p[pkey]){
    p[pkey]=sels.insertItem(p[pkey],0,fullreq)
  } else {
    p[pkey]=sels.insertItem([],0,fullreq)
  }

  return updateObject(state,{plots:p})
}

const requestPlotFromCache=(state,payload) => {
  const pKey=payload.plotKey;
  const pIdx=payload.plotIndex;
  //We reorder the array so that the cached query lies
  //at the top, which is the current head plot.
  const plotToMove=sels.getPlotAt(state,pKey,pIdx);
  const plot=sels.getPlot(state,pKey)
  const allPlots = sels.getPlots(state)
  let plotsToReturn=sels.removeItem(plot,pIdx);
  const plainQueryPosition = sels.getPlotIndexWithoutDataByQuery(plotsToReturn, plotToMove.requestQuery)
  plotsToReturn=plainQueryPosition!==-1?sels.removeItem(plotsToReturn,plainQueryPosition):plotsToReturn;
  plotsToReturn=sels.insertItem(plotsToReturn,0,plotToMove)
  return updateObject(state,{plots:updateObject(
    allPlots,
    {[pKey]:plotsToReturn})
  }
  )
}

//receivePlot ONLY makes sense after requestPlot
//it goes like this:
//1. requestPlot (normaly) in a parent component posts a position in the query queue
//2. some component (a redux aware plot component) picks this change in its props
//3. a call to the axios api is called and chained with a dispatch to receivePlot, where
//   we get the data in response to a given plotKey and requestQuery
const receivePlot = (state,payload) => updatePlot(state,payload)
const updatePlot = (state, payload) => {
  // console.log("Reducer for receive plot")
  const pkey = payload.plotKey;
  const pquery = payload.requestQuery;
  const plotData=payload.plotData;
  const p = sels.getPlot(state,pkey) ;
  let retPlot = {};
  // console.log("Current plot in state for "+pkey,p)
  if(!p){
    // console.log("Plot Request Queue for a plotKey MUST EXIST before receiving")
  }
  if(!_.isArray(p)) {
    console.log("Plot for "+pkey+" must be an array")
  }
      //We 1: have a plot queue for pkey
      //   2: it is an array 
      
      let plotIndex = _(p).findIndex(function (o) { 
        // console.log("Reducer comparing for equality this:")
        // console.log(o.requestQuery)
        // console.log("To this")
        // console.log(pquery)
        let r= _.isEqual(o.requestQuery,pquery) 
        // console.log("Which yields")
        // console.log(r)
        return r
      })
      if (plotIndex === -1) {
        //It was not found. We just set it
        // console.log("Requested plot was not found, pushing into queue")
        // console.log(pquery,plotData)
        // retPlot.push( {...{requestQuery:pquery,
        //                  plotData: plotData}} )
        retPlot=sels.insertItem(p, 0, { requestQuery: pquery, plotData: plotData })
      } else {
        //It does exist, we update the plot's data
        //maybe we shouldnt or should check for cache
        console.log("Requested plot was found by query equality")
        console.log("Setting its index to new plotdata")
        retPlot = sels.updateObjectInArray(p, plotIndex, { requestQuery: pquery, plotData: plotData })
      }
    return updateObject(state, { plots: updateObject(
                                        sels.getPlots(state),
                                        {[pkey]:retPlot}
                                      )})
  }


const parseRacompintelOutput =(state,payload)=> {
  
  // console.log("--------Parsing output------")
  // console.log("--------Payload------")
  // console.log(payload)
    const { output } = payload;
    const { error, has_errors, datakey } = output;
  
  // console.log("--------output------")
  // console.log(output)
  // console.log("--------error------")
  // console.log(error)
  // console.log("--------haserror------")
  // console.log(error)
  if(has_errors[0]){
    console.log("PROTOCOL HAS ERROR")
    console.log("DO SOMETHING WITH IT")
  }
  if(datakey.length===1&&output[datakey[0]]){
        const data = output[datakey[0]]
        if(_.isArray(data)){
          // console.log("Data is an array")
          // console.log(data)
        }else if(_.isObjectLike(data)){
          // console.log("Data is like object")
          // console.log(data)
        } else {
          // console.log("Data is as follows")
          // console.log(data)
        }
        return data
  }else{
    console.log("Data of len 1 provided, but it doesnt pull from output")
    console.log(datakey)
    console.log(output)
    console.log(output(datakey[0]))
  }
}
//Final reducers for protocol
//Data comes just as a list of country codes
//which are the values in the country objects for 
//the oas_countries_harv_imf
const receiveCountrySimpleList=(state,data) => {
  // console.log("reducing received country list")
  // console.log(data)
  const ats = _.map(data,function(val,idx){
            return {id:idx,name:state.country_code_name_map[val],value:val}
        })  
  const allAts=_.assign({},sels.getAvailableToSelect(state));
  // console.log("ALLATS",allAts)
  allAts.oas_countries_harv_imf=_.sortBy(ats,a=>{return a.name})
  return updateObject(state,{availableToSelect:allAts})
} 

const receiveSectorSimpleList  = (state,data) => {
  // console.log("reducing received sector list")
  // console.log(data)
  const sls = state.simpleLists
  const ats = _.assign({},sels.getAvailableToSelect(state));
  ats["all_sectors"] = _.map(data, function (val, idx) {
              let found= _.findIndex(sls["all_sectors"],
                          function(sector){
                            return sector.sector_code==val
                          })
             return _.assign({},sls.all_sectors[found])
  })
  // console.log("Reduced to")
  // console.log(ats["all_sectors"])
  return updateObject(state, {availableToSelect:ats})
}

const selectedSectors = (state,payload) => {
  const {selectedSectors} = payload;
  // console.log("Reducing selected sectors")
  // console.log(selectedSectors)
  return updateObject(
    state,
    {
        selectedSectors:[...selectedSectors]
    }
  )
}

const selectedFromSimpleList = (state,payload) => {
  // console.log("REDUCING::::: selection of simple list")
  const lsl={...state.simpleListsSelections}
  lsl[payload.simpleListsSelection.selectorId]={...payload.simpleListsSelection}
  return updateObject(state,{simpleListsSelections:lsl})
}

const pushQueryRequest = (state,payload) => {
  const qid=payload.queueId;
  const query=payload.query;
  let queue=sels.getQueryQueue(state,qid)
  if(queue===false){
    console.log("reducers.js:pushQueryRequest:queue does not exist",queue,payload)
    return sels.updateObject(state,{queryQueues:
                    sels.updateObject(state.queryQueues,{[qid]:[{...query}]}) 
                        })
  }
  let qindx=sels.getQueueIdxByQuery(queue,query)
  let retq=[]
  if(qindx!==-1) {
    console.log("reducers.js:pushQueryRequest: attempted to push a query already in the queue",queue,query)
    return state
  }

  retq=sels.insertItem(queue,0,{...query})
  return sels.updateObject(state,{
                            queryQueues:
                            updateObject(
                              sels.getQueryQueues(state),
                              {[qid]:retq}
                            )}
                            )
}

const removeQueryRequest = (state,payload) => {
  const qid = payload.queueId;
  const query = payload.query;
  let queue = sels.getQueryQueue(state, qid)
  if (queue === false) {
    console.log("reducers.js:removeQueryRequest: Attempt to remove query from nonexisting queue","selecting all queues",state,sels.getQueryQueues(state),"selecting our queue",qid,sels.getQueryQueue(state,qid))
    return state
  }
  let qidx=sels.getQueueIdxByQuery(queue,query)
  if(qidx===-1){
    console.log("reducers.js:removeQueryRequest: queue exists but query was not found",queue,query)
    return state
  }
  let rq=sels.removeItem(queue,qidx)
  return sels.updateObject(state,{queryQueues:
                            sels.updateObject(
                              sels.getQueryQueues(state),
                              {
                                [qid]:rq
                              }
                            )})
}
export default function(state = initialState, action) {
  switch (action.type) {
    case at.TESTING: {
      const { test } = action.payload;
      return {
        ...state,
        testData:test
      };
    }
    case at.CHANGE_SECTOR_SELECTION: {
      // const { selectedSectors } = action.payload;
      return selectedSectors(state,action.payload);
      }
    case at.REQUEST_AVAILABLE_YEARS_HARV_IMF: {
      return updateObject(state, state.payload);
    }
    case at.RECEIVE_AVAILABLE_COUNTRIES_YEAR_HARV_IMF: {
      return  receiveCountrySimpleList(state, parseRacompintelOutput(state, action.payload))
    }
    case at.RECEIVE_AVAILABLE_SECTORS_COUNTRY_YEAR_HARV_IMF: {
      return receiveSectorSimpleList(state,parseRacompintelOutput(state,action.payload))
    }
    case at.CHANGE_SIMPLE_LIST_SELECTION: {
      return selectedFromSimpleList(state,action.payload)
    }
    case at.REQUEST_PLOT:{
      console.log("After dispatch in reducers reqplot")
      console.log(action)
      return requestPlot(state,action.payload)
    }
    case at.RECEIVE_PLOT: {
      console.log("Reducing  plot reception in")
      console.log(action)
      return receivePlot(state,action.payload)
    }
    case at.REQUEST_PLOT_FROM_CACHE: {
      console.log("Plot requested from cache")
      console.log(action)
      return requestPlotFromCache(state,action.payload)
    }
    case at.REQUEST_TABLE:{
      console.log("Table Requested")
      console.log(action)
      return requestTable(state,action.payload)
    }
    case at.REQUEST_TABLE: {
      console.log("Table Requested")
      console.log(action)
      return requestTableFromCache(state, action.payload)
    }
    case at.RECEIVE_TABLE: {
      console.log("Table Received")
      console.log(action)
      return receiveTable(state, action.payload)
    }
    case at.PUSH_QUERY_REQUEST: {
      console.log("Pushing query request")
      console.log(action)
      return pushQueryRequest(state,action.payload)
    }
    case at.REMOVE_QUERY_REQUEST: {
      console.log("Removing query request")
      console.log(action)
      return removeQueryRequest(state,action.payload)
    }
    default:
      return state;
  }
}
