// // redux/actions.js
import * as at from "./actiontypes.js"
import { dispatch } from "rxjs/internal/observable/range"
//This two return atrings with enters. Think about that
export const loadLibrary = () => (load("/library/"))

export const loadRacompintelLibrary = () =>(load("/library/racompintel/R/"))

export const load_quality_vs_harvard_correlations_by_country_year= (params=false) =>{
    return( post("/library/racompintel/R/quality_vs_harvard_correlations_by_country_year/print",params))
  }
export const load_quality_vs_harvard_correlations_by_country_year_data_table = (params=false) => {
  return (
    post("/library/racompintel/R/w_get_table_for_quality_vs_harvard_correlations_query/json", params)                                                                   )
}
export const requestPlot = (plotKey,requestQuery) => {
  // console.log("REQUESTING PLOT")
  // console.log(plotKey)
  // console.log(requestQuery)
  return(
      {
        type: at.REQUEST_PLOT,
        payload: {
            plotKey:plotKey,
            requestQuery:requestQuery,
            plotData:{}
          }
      }
      )
  }

  export const requestPlotFromCache = (plotKey,plotIdx) => {
    return {
      type:at.REQUEST_PLOT_FROM_CACHE,
      payload:{
        plotKey:plotKey,
        plotIndex:plotIdx
      }
    }
  }

  export const requestTable = (plotKey,requestQuery) => {
    return {
      type:at.REQUEST_TABLE,
      payload:{
        plotKey:plotKey,
        requestQuery:requestQuery,
        dataTable:[]
      }
    }
  }

  export const requestTableFromCache = (plotKey,plotIdx) => {
    return {
      type: at.REQUEST_TABLE_FROM_CACHE,
      payload: {
        plotKey: plotKey,
        plotIndex: plotIdx
      }
    }
  }

  export const receiveTable = (plotKey,requestQuery,tableData) => {
    return (
      {
        type: at.RECEIVE_TABLE,
        payload: {
          plotKey: plotKey,
          requestQuery: requestQuery,
          tableData: tableData
        }
      }
    )
  }
  export const receivePlot = (plotKey,requestQuery,plotData) => {
    // console.log("RECEIVING PLOT")
    // console.log(plotKey)
    // console.log(requestQuery)
    // console.log(plotData)
    return (
      {
        type: at.RECEIVE_PLOT,
        payload: {
          plotKey: plotKey,
          requestQuery: requestQuery,
          plotData:plotData
        }
      }
    )
  }


export const w_distinct_countries_by_year = (year) => {
    return( post("/library/racompintel/R/w_distinct_countries_by_year/json",{"year":year}))
}

export const w_available_sectors_per_country_year = (country,year) => {
    return( post("/library/racompintel/R/w_available_sectors_per_country_year/json",
    {"country":country,"year":year})
  )
}

export const w_available_sectors = () => {
  return(post("/library/racompintel/R/load_sectors/json"))
}

export const w_available_years_for_qual_and_harvard = () => {
  return(post("/library/racompintel/R/w_available_years_for_qual_and_harvard/json"))
}

export const w_all_oas_countries_for_qual_harvard = () => {
  return (post("/library/racompintel/R/w_all_oas_countries_for_qual_harvard/json"))
}

export const getAvailableYears = (dispatch) => (
  w_available_years_for_qual_and_harvard.then({
    function (response) {
        // console.log("got available years for qual and harvard")
        // console.log(response)
      return (receiveAvailableYears(response.data))
    }
  })
)

export const requestSectorsHarvIMF=(country,year)=>{
  return w_available_sectors_per_country_year(country,year)
}

export const requestCountriesByYearHarvIMF=(year) => {
  return w_distinct_countries_by_year(year)
}

export const post = (url,params=false) => {
  const ret = {
    type: at.AXIOS_LOAD,
    payload: {
      request: {
        url: url,
        method: "post"
      }
    }
  } 
      if(params){
        ret.payload.request["data"]=params;
      } 
      return {...ret}
}
export const load = (url) => ({
  type:at.AXIOS_LOAD,
  payload:{
    request:{
      url:url
    }
  }
})

export const changeSectorSelection = (ssect) => ({
  type: at.CHANGE_SECTOR_SELECTION,
  payload:{
    selectedSectors:ssect
  }
})

export const changeSimpleListSelection = (selectorId, listKey,valueKey,selection) => ({
  type:at.CHANGE_SIMPLE_LIST_SELECTION,
  payload:{
    simpleListsSelection:{
                  selectedItems:selection,
                  selectorId:selectorId,
                  listKey:listKey,
                  valueKey:valueKey
    }

  }
})

export const requestAvailableYears = (years) => ({
  type:at.REQUEST_AVAILABLE_YEARS_HARV_IMF,
  payload:{
    requestingYears:true
  }
})

export const receiveAvailableYears = (years) => (updateAvailableYears(years))

export const updateAvailableYears = (years) => ({
  type:at.RECEIVE_AVAILABLE_YEARS_HARV_IMF,
  payload:{
    availableYears:years,
    requestingYears:false
  }
})

export const receiveAvailableCountriesYearHarvIMF = (output) => ({
  type: at.RECEIVE_AVAILABLE_COUNTRIES_YEAR_HARV_IMF,
  payload: {
    output: output,
    requestingHarvIMFCountries: false
  }
})


export const receiveAvailableSectorsCountryYearHarvIMF = (output) => ({
  type: at.RECEIVE_AVAILABLE_SECTORS_COUNTRY_YEAR_HARV_IMF,
  payload: {
    output: output
  }
})


export const pushQueryRequest = (queueId,query) => {
  return {
    type:at.PUSH_QUERY_REQUEST,
    payload:{
      queueId:queueId,
      query:query
    }
  }
}

export const removeQueryRequest = (queueId,query) => {
  return {
    type: at.REMOVE_QUERY_REQUEST,
    payload:{
      queueId:queueId,
      query:query
    }
  }
}

//Test action
export const testDo = (test) => ({
  type:at.TESTING,
  payload:  {testData:"test"+test}
})
