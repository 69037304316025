import React from 'react'
import {connect} from 'react-redux'
import GenericPlot from './GenericPlot.js'
import {load_quality_vs_harvard_correlations_by_country_year, receivePlot,requestPlotFromCache,pushQueryRequest,removeQueryRequest} from "../../state/actions.js"
import { dispatch } from 'rxjs/internal/observable/pairs'
import Spinner from 'react-bootstrap/Spinner'
import _ from 'lodash'
import * as sels from '../../state/selectors.js'
import Row from "react-bootstrap/Row"
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

class RemotePlotRedux extends React.Component {
state={jsonData:{}}

componentDidMount= () => {
  console.log("RemotePlot Plot did mount")
  console.log(this.props)
}
  componentDidUpdate(prevProps, prevState){
    const realResp={};
    if(this.props.toPlot===undefined 
      ){
      console.log("Nothing to plot today")
      console.log("Look at my props!", this.props)
      return ;
    }
    else{
      console.log("something to plot today")
    }

    //Plot is current, at head and has data. Nothing to do.
    if(sels.getOrFalse(this.props, "toPlot")!==false && !_.isEqual({},sels.getOrFalse(this.props,"toPlot"))){
      console.log("RemotePlotRedux:componentdidupdate toPlot has data, nothing to do",sels.getOrFalse(this.props,"toPlot"))
      return ;
    }

    console.log("RemotePlotRedux: componentDidUpdate: toPlot is false or empty prevpr",prevProps,"prevstate:",prevState,"this.props",this.props)

    let plotIndex = sels.getPlotIndexWithDataByQuery(this.props.plots,this.props.toPlotQuery)

    if (plotIndex!==-1)
    {
      //Plot is in cache
      console.log("RemotePlotRedux: componentDidUpdate: plot found in cache by query and found not empty",plotIndex,this.props.plots)
      this.props.requestPlotFromCache(this.props.plotKey,plotIndex)
      return;
    }

    if(sels.getOrFalse(this.props,"toPlotQuery")&& 
        (sels.getQueueIdxByQuery(this.props.queryQueue,this.props.toPlotQuery) ===-1 ) )
        {
      //This is a request. Get the data
      console.log("RemotePlotRedux: Requesting plot from componentdidupdate because this is false or empty:")
      console.log(this.props.toPlot)
      console.log("And this is not")
      console.log(this.props.toPlotQuery)
        this.props.getPlotFromQuery(this.props.plotKey, this.props.toPlotQuery)
     } 

  }


  render=() => {
     let t,l,d,Plot,ret,spin,shouldRenderSpin,shouldRenderPrev,shouldRenderCurr;
     //TODO: make a logical variable out of T that does not overwrite the state
    shouldRenderSpin = this.props.isPlotQueryUnderRequest;
    shouldRenderPrev= this.props.isPlotQueryUnderRequest && (sels.getOrFalse(this.props,"prevPlot"))
    if (shouldRenderSpin)
    {
      spin = <Spinner animation="border" variant="success"></Spinner>
    }else
    {
      spin = <p></p>
    }
    t= shouldRenderPrev? sels.copyJson(this.props.prevPlot):
                          sels.copyJson(this.props.toPlot)
      
    if (t){
       l=sels.getOrFalse(t,'layout');
       if(l){
         l.autosize=true;
         l.height=undefined;
         l.width=undefined;
       }
       Plot=<GenericPlot jsonData={t}/>    
      }
    ret = <Container fluid={true}>
      <Row mt={5}><Col md={{ span: 4, offset: 5 }}>{console.log("RENDERING REMOTE REDUX CURRENTQUERY",this.props)} {spin} </Col></Row>
      <Row ><Col md={true}>{Plot}</Col></Row>
    </Container>

    return ret
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPlotFromQuery: (plotkey,query) => {
      let lpk=plotkey
      let lq=query
        dispatch(pushQueryRequest("remotePlotRedux",query))
        dispatch(load_quality_vs_harvard_correlations_by_country_year(query)).then(
          function(response){
            dispatch(receivePlot(lpk,lq,response.payload.data))
            dispatch(removeQueryRequest("remotePlotRedux",query))
          }
        )
    },
    requestPlotFromCache: (plotKey,plotIndex) => {
      dispatch(requestPlotFromCache(plotKey,plotIndex))
    }
  }
}
const mapStateToProps=(state,myprops)=>{
    // let myplots=state.plots[myprops.plotKey]
    let myplots=sels.getPlot(state,myprops.plotKey)
    let currentQuery=sels.getHeadPlotQuery(state,myprops.plotKey)
    return {plots:myplots,
             toPlot:sels.getHeadPlotData(state,myprops.plotKey),
             toPlotQuery:currentQuery,
      isPlotQueryUnderRequest:sels.isPlotQueryUnderRequest(state,myprops.plotKey,currentQuery),
      prevPlot: sels.getPlotAt(state, myprops.plotKey, 1) ? sels.getPlotAt(state, myprops.plotKey, 1).plotData:null,
      currentQuery:currentQuery,
      queryQueue:sels.getQueryQueue(state,"remotePlotRedux")
            } 
}
export default connect(mapStateToProps,mapDispatchToProps)(RemotePlotRedux)
