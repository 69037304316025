import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import FileTable from "../../components/UI/FileTable.js";
export const _frontmatter = {
  "title": "Three datasets",
  "path": "/the-three-datasets",
  "date": "2019-01-29T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`The Datasets`}</h1>
    <p>{`There are three main sources of data in the current version of the Atlas:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://data.imf.org/?sk=A093DF7D-E0B8-4913-80E0-A07CF90B44DB"
        }}>{`IMF Quality Index data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://atlas.cid.harvard.edu/"
        }}>{`The Atlas of Economic Complexity`}</a>{` from Harvard's Growth Lab `}</li>
      <li parentName="ul">{`And a curated set Capabilities, also called General Database in the
`}<a parentName="li" {...{
          "href": "/thedocument.pdf"
        }}>{`Signs of Competitiveness`}</a>{` research document`}</li>
    </ul>
    <h2>{`Exploration`}</h2>
    <p>{`Each of this datasets have their own completeness and research behind them and,
happily, an exploration tool for them.`}</p>
    <ul>
      <li parentName="ul">{`The IMF Quality Index can be explored directly in the IMF data website
`}<a parentName="li" {...{
          "href": "http://data.imf.org/exportquality"
        }}>{`http://data.imf.org/exportquality`}</a></li>
      <li parentName="ul">{`Harvard's Atlas of Economic Complexity provides their superb visualization
tool at The Growth Lab at Harvard University. The Atlas of Economic Complexity.
`}<a parentName="li" {...{
          "href": "http://www.atlas.cid.harvard.edu"
        }}>{`http://www.atlas.cid.harvard.edu`}</a>{`.`}</li>
      <li parentName="ul">{`The Capabilities General Database used for the research showcased here in the
Competitive Intelligence Atlas for the Americas is explored through this very
website.`}</li>
    </ul>
    <h2>{`Raw Versions`}</h2>
    <p>{`Datasets change. In fact, just as our research was ongoing, the growth lab published new versions for their data. The IMF could also change and/or update their data at any time and, with your help, we might find ways to update and improve the Capabilities database curated for this research.`}</p>
    <p>{`You can find the raw data used in this version of the atlas, for each of the source datasets, through the following links:`}</p>
    <FileTable mdxType="FileTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      