import {createSelector} from 'reselect'
import _ from "lodash"
import { update } from 'timm';

export const ocpBaseUrl = state => getOrFalse(state,"ocpBaseUrl");
export const getSimpleLists = state => getOrFalse(state,"simpleLists");
export const getSimpleList = (state,listname) => getOrFalse(getSimpleLists(state),listname);
export const getSimpleListsSelections = (state) => getOrFalse(state,"simpleListsSelections");
export const getSimpleListsSelection = (state,selectorId) => getOrFalse(getSimpleListsSelections(state),"selectorId") 
export const getSimpleListsSelectionSelectedItems = (state,selectorId) => getOrFalse(
                                                            getSimpleListsSelection(state,selectorId),
                                                            "selectedItems")

//PLOTS
export const getPlots = (state) => getOrFalse(state,"plots");
export const getPlot = (state,plotKey) => getOrFalse(getPlots(state),plotKey);  
export const getHeadPlot = (state,plotKey) => getOrFalse(getPlot(state,plotKey),0)
export const getHeadPlotQuery = (state, plotKey) => getOrFalse(getHeadPlot(state, plotKey),"requestQuery")
export const getPlotAt = (state,plotKey,at) => getPlot(state,plotKey)?getOrFalse(getPlot(state,plotKey),at):false;
export const getHeadPlotData = (state, plotKey) => getOrFalse(getHeadPlot(state,plotKey), "plotData")

export const getPlotByQuery = (state,plotKey,query) => {
    const p=getPlot(state,plotKey);
    const pquery=query
    if (!p) {
        console.log("reducers.getPlotByQuery: plot by" + plotKey + "Not found in state", plotKey, getPlots(state))
    }
    return findPlotByQuery(p,pquery)   
}

export const findPlotByQuery=(plotArray,query) =>{
    const plotIndex=getPlotIndexByQuery(plotArray,query)
    return plotIndex !== -1 ? plotArray[plotIndex] : false;
}
export const getPlotIndexByQuery=(plotArray,query)=>{
    if (!_.isArray(plotArray)) {
        console.log("selector:findPlotByQuery plotArray is not an array!", plotArray)
        return -1;
    }
    let p = plotArray;
    // console.log("selectors.getPlotIndexByQuery finding query",query,"in array",plotArray)
    let plotIndex = _(p).findIndex(function (o) {
        // console.log("selectors.getPlotIndexByQuery: Reducer comparing for equality this:")
        // console.log(o.requestQuery)
        // console.log("selectors.getPlotIndexByQuery:To this")
        // console.log(query)
        let r = _.isEqual(o.requestQuery, query)
        // console.log("selectors.getPlotIndexByQuery:Which yields")
        // console.log(r)
        return r
    })
    // console.log("selectors.getPlotIndexByQuery found index at:",plotIndex)
    return plotIndex;
}
export const getPlotIndexWithDataByQuery=(plotArray,query)=>{
    // console.log("selectors.getPlotIndexWithDataByQuery:",plotArray,query)
    let idx= _(plotArray).findIndex(function(o){
        // console.log("selectors.getPlotIndexWithDataByQuery:comparing",o,"to",query)
        return _.isEqual(o.requestQuery,query) && 
                (o.plotData!==false)&&
                (o.plotData!==undefined)&&
                (!_.isEqual({},o.plotData))
    })
    // console.log("selectors.getPlotIndexWithDataByQuery:returning",idx);
    return idx
}
export const getPlotIndexWithoutDataByQuery=(plotArray,query)=> {
    // console.log("selectors.getPlotIndexWithoutDataByQuery:", plotArray, query)
    let idx = _(plotArray).findIndex(function (o) {
        // console.log("selectors.getPlotIndexWithoutDataByQuery:comparing", o, "to", query)
        return _.isEqual(o.requestQuery, query) && (
            (o.plotData === false) ||
            (o.plotData !== undefined) ||
            (!_.isEqual({}, o.plotData))
            )
    })
    // console.log("selectors.getPlotIndexWithoutDataByQuery:returning", idx);
    return idx
}
export const hasPlotData=(state,plotKey,query) => {
    const p = getPlotByQuery(state, plotKey, query)
    return p.plotData !== undefined && !_.isEqual(p.plotData,{});
}
export const isPlotQueryUnderRequest=(state,plotKey,query) => {
    return !hasPlotData(state,plotKey,query)
}

export const hasTableData=(state,plotKey,query) => {
    const p=getPlotByQuery(state,plotKey,query);
    return plotHasTableData(p)
}
export const plotHasTableData=(plot) => {
    return getDataTable(plot) !==false && !_.isEqual(getDataTable(plot),[])
}
export const plotHasTableUnderRequest =(plot) => {
    return !plotHasTableData(plot)
}
export const isDataTableUnderRequest = (state,plotKey,query) => {
    return !hasTableData(state,plotKey,query)
}



export const getDataTablePlotIndexWithDataByQuery=(plotArray,query) => {
    const pQuery={...query}
    console.log("getdatatableplotindexwithdata enterred",plotArray,query)
    if(plotArray===false || plotArray===undefined){
        console.log("getdatatableplotindexwithdata plotarray is undefined",plotArray,query)
    }
    let ret = _(plotArray).findIndex(function(plot){

        let r= _.isEqual(query,plot.requestQuery)&&existsWithData(plot,"dataTable")
        console.log("Found status is:",r,"because this",plot.requestQuery,"equals this:",pQuery," AND plot.plotData is not empty:",plot.dataTable,"exists with data says",existsWithData(plot,"dataTable"),"getOrFalse",getOrFalse(plot,"dataTable"))
        return r;
    })
    console.log("getdatatableplotindexwithdata exited",ret)
    return ret;
}

export const getDataTablePlotIndexWithoutDataByQuery=(plotArray,query) => {
    let idx= (_.findIndex(plotArray, plot => {
        return _.isEqual(query, plot.requestQuery) ? !getOrFalse(plot, "dataTable") : false
    }))
}


export const getDataTableColumns = (plot) => {
    const p=plot;
    const table=getDataTable(plot);
    if(table && !_.isEqual(table,[])){
        return _.keys(table[0])
    }
    return false;
}
export const getDataTableColumnsReactTableConformant = (state,plot)=> {
    const dataTableHeaders=getDataTableColumns(plot)
    const variableToNameMap = getOrFalse(state,"cors_variable_name_map")
    return _(dataTableHeaders).map(st => {
        return {name:variableToNameMap[st],selector:st,sortable:true,wrap:true}
    }).value()
}
export const getDataTable = (plot) => {
    return getOrFalse(plot,"dataTable") 
}
//Query queues

export const getQueryQueues = (state) => {
    return getOrFalse(state,"queryQueues")
}

export const getQueryQueue = (state,queueId) => {
    return getOrFalse(getQueryQueues(state),queueId)
}

export const getQueueIdxByQuery = (queryQueue,query) =>{
    if(_.isArray(queryQueue) && _.isObject(query))
    {
        return _(queryQueue).findIndex(q=> {return _.isEqual(q,query)})
    }
    else {
        return -1
    }
}

//Various Utilities
export const queryForDisplay = (state,query) => {
    return updateObject(
        query,
        {country:codeToCountryName(state,query.country),
        sectors:sectorsToSectorNameRenderText(state,query.sectors)}

    )
}
export const sectorsToSectorNameRenderText = (state,sectorArray) => {
    let s= sectorsToSectorName(state,sectorArray)
    if(_.isArray(s)){
        return s.join(", ");
    }
    return s;
}

export const sectorsToSectorName = (state,sectorArray)=> {
    const sectorNameMap = getOrFalse(state,"sector_code_name_map")
    if(sectorNameMap === false) {
        console.log("Sector name map not found by sector_code_name_map")
        console.log(state)
    }
    console.log("Sector name maps looks like:",sectorNameMap)
    if(sectorArray.length == _.keys(sectorNameMap).length)
    {
        return "All"
    }
    return _(sectorArray).map(e => {
            return getOrFalse(sectorNameMap,e)
    }).value()
}

export const codeToCountryName = (state,code) => {
    const nmap = getOrFalse(state,"country_code_name_map")
    if(nmap===false){
        console.log("codeToCountryName: Name map not found in state!",state)
        return false;
    }
    const ret=getOrFalse(nmap,code)
    return ret;
}

//AvailableToSelect
export const getAvailableToSelect =(state) => getOrFalse(state,"availableToSelect");
export const getAvailableToSelectByKey = (state,key)=>getOrFalse(getAvailableToSelect(state),key)

//ReduxRemotePlot component properties
export const getCurrentQueryFromProps = (props) => getOrFalse(props,"currentQuery")
export const getFieldFromCurrentQuery = (props,field) => getOrFalse(getCurrentQueryFromProps(props),field)

//Generic getters

export const copyJson = (j) => { return JSON.parse(JSON.stringify(j))}

export const getOrFalse=(obj,key) => {
    if(_.isArray(obj)){
        if(_.isInteger(key)){
            return obj[key]===undefined?false:obj[key]
        }
    }
    else if(_.isObject(obj)){
    return _.has(obj,key)? obj[key] :false;  
    }
    return false
}

export const updateObject = (oldo, newo) => {
    return {
        ...oldo,
        ...newo
    }
}

export const objectIsEmpty = (obj) => _.isEqual({}, obj) || _.isEqual([],obj)

export const exists = (obj,key) => getOrFalse(obj,key) 

export const existsWithData = (obj,key) => exists(obj,key)!==false && (!objectIsEmpty(obj[key]))

//ARRAY FUNCTIONS
export const insertItem=(array, index,item) => {
    let newArray = array.slice()
    newArray.splice(index, 0, item)
    return newArray
}

export const removeItem = (array, idx) => {
    return array.filter((item, index) => index !== idx)
}

export const updateObjectInArray = (array, idx,it) => {
    return array.map((item, index) => {
        if (index !== idx) {
            // This isn't the item we care about - keep it as-is
            return item
        }

        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...it
        }
    })
}
