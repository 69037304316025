import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
export const _frontmatter = {
  "title": "Competitiveness Policies for Inclussive Growth",
  "path": "/signs-of-competitive-research-paper",
  "date": "2019-10-10T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Signs of Competitiveness`}</h1>
    <h2>{`Competitiveness Policies for Inclussive Growth`}</h2>
    <h3>{`A priorization proposal`}</h3>
    <p>{`What can be done to reduce the economic, productive, social and digital divides of our countries? How can we integrate those that feel excluded? How do we generate inlclusive economic growth and competitiveness?`}</p>
    <p>{`What can be done to reduce the economic, productive, social and digital divides of our countries? How can we integrate those that feel excluded? How do we generate inclusive economic growth and competitiveness?`}</p>
    <p>{`Taking into account the infrastructure and capabilities restrictions of our region's countries, how can we priorize the selection of competitiveness policies from so many options of possible reforms, in such a way that we implement those that have the largest impact on inclusive growth? What capacities must we generate in the region and within each country so that we are not excluded from new production pathways? What is the best way ahead for the inclusive growth of each country and what are the most adequate policies to implement in the short, medium and long term?`}</p>
  <Container className={["rounded", "mt-4", "p-2"]} mdxType="Container">
              <Row className="mb-4" mdxType="Row">
                <Col className={["text-center", "border-top", "border-bottom"]} mdxType="Col">Download the report here</Col>
              </Row>
              <Row className={["text-center"]} mdxType="Row">
                <Col className={['lr-2', 'ml-2']} mdxType="Col"><Button variant="outline-dark" mdxType="Button"> 	English </Button></Col>
                <Col className={['lr-2', 'ml-2']} mdxType="Col"><Button variant="outline-dark" mdxType="Button"> 	Español </Button></Col>
                <Col className={['lr-2', 'ml-2']} mdxType="Col"><Button variant="outline-dark" mdxType="Button"> 	Français </Button></Col>
                <Col className={['lr-2', 'ml-2']} mdxType="Col"><Button variant="outline-dark" mdxType="Button"> 	Portugués </Button></Col>
              </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      