export const TESTING="TESTING"
export  const GET_SECTOR_TABLE = "GET_SECTOR_TABLE"
export  const AXIOS_LOAD="LOAD"
export const CHANGE_SECTOR_SELECTION= "CHANGE_SECTOR_SELECTION"
export const REQUEST_AVAILABLE_YEARS_HARV_IMF="REQUEST_AVAILABLE_YEARS_HARV_IMF"
export const RECEIVE_AVAILABLE_YEARS_HARV_IMF="RECEIVE_AVAILABLE_YEARS_HARV_IMF"
export const CHANGE_SIMPLE_LIST_SELECTION = "CHANGE_SIMPLE_LIST_SELECTION"
export const REQUEST_AVAILABLE_COUNTRIES_YEAR_HARV_IMF = "REQUEST_AVAILABLE_COUNTRIES_YEAR_HARV_IMF"
export const RECEIVE_AVAILABLE_COUNTRIES_YEAR_HARV_IMF = "RECEIVE_AVAILABLE_COUNTRIES_YEAR_HARV_IMF"
export const RECEIVE_AVAILABLE_SECTORS_COUNTRY_YEAR_HARV_IMF = "RECEIVE_AVAILABLE_SECTORS_COUNTRY_YEAR_HARV_IMF" 
export const REQUEST_PLOT="REQUEST_PLOT"
export const RECEIVE_PLOT="RECEIVE_PLOT"
export const REQUEST_PLOT_FROM_CACHE ="REQUEST_PLOT_FROM_CACHE"
export const REQUEST_TABLE = "REQUEST_TABLE"
export const RECEIVE_TABLE = "RECEIVE_TABLE"
export const REQUEST_TABLE_FROM_CACHE = "REQUEST_TABLE_FROM_CACHE"

export const PUSH_QUERY_REQUEST = "PUSH_QUERY_REQUEST"
export const REMOVE_QUERY_REQUEST = "REMOVE_QUERY_REQUEST"