// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-course-jsx": () => import("../src/pages/course.jsx" /* webpackChunkName: "component---src-pages-course-jsx" */),
  "component---src-pages-data-availability-js": () => import("../src/pages/data-availability.js" /* webpackChunkName: "component---src-pages-data-availability-js" */),
  "component---src-pages-harvard-imf-correlations-js": () => import("../src/pages/HarvardIMFCorrelations.js" /* webpackChunkName: "component---src-pages-harvard-imf-correlations-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-testingcorrelationplotcomponent-js": () => import("../src/pages/testingcorrelationplotcomponent.js" /* webpackChunkName: "component---src-pages-testingcorrelationplotcomponent-js" */),
  "component---src-pages-testingmapstatetoprops-js": () => import("../src/pages/testingmapstatetoprops.js" /* webpackChunkName: "component---src-pages-testingmapstatetoprops-js" */)
}

