import GenericPlot from "../../../../src/components/Plots/GenericPlot.js";
import YearAvailabilityData from "../../../../src/data/plots/capabilities/plot_year_coverage.json";
import YearAvailabilityBarsData from "../../../../src/data/plots/capabilities/plot_years_vs_ammount_of_countries_per_category.json";
import CountryYearScaleOf8Data from "../../../../src/data/plots/capabilities/countries_heatmap_scale_of_8.json";
import TestFromOpenCPU from "../../../../src/data/plots/testingopencpu.json";
import React from 'react';
export default {
  GenericPlot,
  YearAvailabilityData,
  YearAvailabilityBarsData,
  CountryYearScaleOf8Data,
  TestFromOpenCPU,
  React
};