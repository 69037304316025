import React from 'react'
import loadable from '@loadable/component'
const LazyPlot = loadable(() => import('react-plotly.js') ,{
  fallback: (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ),
})

class GenericPlot extends React.Component {

componentDidMount= () => {
  // console.log("Generic Plot did mount")
}

handleClick = (e) => {
  console.log("GenericPlot Plotly Clicked, e:",e)
}
handleRestyle = (e) => {
  console.log("GenericPlot Plotly Restyled, e:", e)
}
  handlePlotlyError= (e) => {
    console.log("GenericPlot Plotly Error, e:", e)
  }
  handlePlotlyRelayout=(e) => {
    // console.log("GenericPlot plotly relayout,e:",e)
  }
  render=() => {
    return (
     <LazyPlot
        data={this.props.jsonData.data}
        layout={this.props.jsonData.layout}
        config={this.props.jsonData.config}
        useResizeHandler={true}
        debug={true}
        responsive={true}
        className="lazy-plotly-component"
        onClick={this.handleClick}
        onRestyle={this.handleRestyle}
        onError={this.handlePlotlyError}
        onRelayout={this.handlePlotlyRelayout}
        style={{
          width: "100%"
        }}
      />
    )
  }
}

export default GenericPlot
