import React from "react"
import {Typeahead} from 'react-bootstrap-typeahead';
import {connect} from 'react-redux'
import {changeSimpleListSelection} from '../../../state/actions.js'
import "react-bootstrap-typeahead/css/Typeahead-bs4.css"
import _ from "lodash"
import * as myselectors from  '../../../state/selectors.js'

class SimpleListTypeAhead extends React.Component {
  state={
    selected:[]
  }
  componentDidMount= () => {
    if(_.isEmpty(this.state.selected)&&!_.isEmpty(this.props.currentlySelectedFull)){
      this.setState({selected:this.props.currentlySelectedFull})
    }
  }
  handleChange = () => {
    // console.log("simpleListTypeAhead:Input Changed, args follow") 
    // console.log(this.state.selected)
    // console.log("simpleListTypeAhead:Extracting ids")
    const selection={...this.state.selected}
    // console.log("simpleListTypeAhead:From")
    // console.log(selection)
    const selids=_(selection).toArray().value()
    // console.log("simpleListTypeAhead:Dispatching simple list "+this.props.selectorUID+" selection with")
    //  console.log(selids)
    this.props.changeSimpleListSelection(this.props.selectorUID,this.props.listKey,this.props.valueKey,[...selids])
    this.props.onChange(this.props.selectorUID,[...selids])
  }
  curList = () => {
    return this.props.availableToSelect[this.props.listKey];
  }
  findCurSel = () => {
    // console.log("simpleListTypeAhead:FINDING CURSEL")
    // console.log(this)
    const ret = this.props.simpleListsSelections[this.props.selectorUID]
    // console.log(ret)
    return ret
  }
  getSelectionsByLabelKey = ()=> {
    const labelKey=this.props.labelKey
    
    const csel=this.props.simpleListsSelections[this.props.selectorUID]?
              this.props.simpleListsSelections[this.props.selectorUID]:
              false;
    if(csel&&csel.selectedItems){

      // console.log("simpleListTypeAhead:WHAT TH HELL IS CSEL")
      // console.log(csel)
      return (<p> {
        _.map(csel.selectedItems,(o)=>{return o[labelKey]}).join(', ')
      }</p>)
    }
    return (<p>Nothing selected for {this.props.listKey} </p>)
  }
  render= () => {
    //This component receives a list key
    //as defined in GLBALS.js
    const {listKey,selectorUID} = this.props;
    // const yrsel=this.findCurSel().selectedItems.map((o)=><p>o</p>)
    // console.log("simpleListTypeAhead:Rendering selected items list")
    // console.log(this.props)
    
   return(
    <>
    {/* Selected {this.props.placeholder}: {this.getSelectionsByLabelKey()} */}
      <Typeahead
        id={selectorUID}
        labelKey={this.props.labelKey}
        multiple={this.props.multiple}
        options={this.props.availableToSelect[this.props.listKey]}
        placeholder={this.props.placeholder}
        onChange={(selected) => { this.setState({selected:selected}, this.handleChange) }}  
        {...(this.props.defaultSelected?
                  {defaultSelected:this.props.defaultSelected}:
                  undefined) }
        selected={this.state.selected}
      />
      {/* <div>{_.map({...this.state.selected},(o)=> _.toString(_.keys(o))+_.toString(_.values(o)) )  }</div> */}
    </>)

  }

}

const mapStateToProps = (state,ownProps) => {

  const {simpleListsSelections,simpleLists,plots,availableToSelect} = state;
  const currentlySelected = ownProps.currentlySelected;
  const currentlySelectedFull= !_.isArray(currentlySelected)?[_.find(availableToSelect[ownProps.listKey],
    (e)=>e[ownProps.valueKey]==currentlySelected)]:_.map(currentlySelected,
      cs=>{
        return _.find(availableToSelect[ownProps.listKey],a=>{
            return a[ownProps.valueKey]==cs
            }
            )
  })
      

  return {
      simpleListsSelections: simpleListsSelections,
      simpleLists: simpleLists,
      plots: plots,
      availableToSelect:availableToSelect,
      currentlySelectedFull:currentlySelectedFull
    }
  
}

const actionCreators = {
  changeSimpleListSelection
}


export default connect(mapStateToProps,actionCreators)(SimpleListTypeAhead)
