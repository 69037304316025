import React from "react";
import { connect } from 'react-redux'
// import RemotePlotRedux from '../components/Plots/RemotePlotRedux.js'
import RemotePlotRedux from '../RemotePlotRedux'
import {
    requestCountriesByYearHarvIMF, receiveAvailableCountriesYearHarvIMF,
    requestSectorsHarvIMF, receiveAvailableSectorsCountryYearHarvIMF,
    requestPlot
} from '../../../state/actions.js'
// import SimpleListTypeAhead from '../components/UI/SimpleListTypeAhead/SimpleListTypeAhead'
import SimpleListTypeAhead from '../../UI/SimpleListTypeAhead/SimpleListTypeAhead.js'

// import { getSimpleListsSelection } from '../state/selectors'
import { getSimpleListsSelection } from '../../../state/selectors.js'
import * as sels from '../../../state/selectors.js'
import _ from 'lodash'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

const mapStateToProps = (state, ownprops) => {

    const { simpleListsSelections, simpleLists, plots } = state;
    // console.log("CorrelationPlotComponent:Mapping state to props")
    // console.log("CorrelationPlotComponent:state")
    // console.log(state)
    // console.log(ownprops)
    const currentQuery = constructQuery(sels.getSimpleListsSelections(state), ownprops.defaultQuery)
    return {
        simpleListsSelections: simpleListsSelections,
        simpleLists: simpleLists,
        plots: plots,
        currentQuery: currentQuery
    }
    // console.log("CorrelationPlotComponent:returning from mapstatetoprops")
    // console.log(r)

}

const constructQuery = (simpleListsSelections,defQ=false) => {
    // console.log("CorrelationPlotComponent:constructQuery")
    // const props=_.assign({},inprops)
    console.log("constructQuery: params",simpleListsSelections,defQ)
    const sls = sels.updateObject({},simpleListsSelections)
    // console.log(props)
    let defaultQuery = defQ? sels.updateObject({}, defQ) : {
        year: 2007,
        country: "PRY",
        sectors: [0,1,2,5,6,8]
    }
    if (sls === false) {
        console.log("CorrelationPlotComponent: sent empty simple lists selections")
        return {...defaultQuery}
    }
    //Call this with the plain this.props or parameters to dispatch, map...etc 
    const queryTemplate = {
        YEAR_FOR_CORR: {
            fieldName: 'year',
            parser: parseInt
        },
        SECTOR_FOR_CORR: {
            fieldName: 'sectors',
            parser: (sectors) => {
                return (_.isArray(sectors) ? _.map(sectors, (s) => parseInt(s, 10))
                    : [parseInt(sectors, 10)]
                )
            }
        },
        COUNTRIES_FOR_YEAR: {
            fieldName: 'country',
            parser: _.toString
        }
    }
    let ret = _.mapValues(sls, function (l, lname) {
        // console.log("CorrelationPlotComponent:Constructing query from simpleListSelection:")
        // console.log(l)
        let r
        let vkey = l.valueKey
        const qt = queryTemplate
        if (l.selectedItems === undefined || l.selectedItems.length == 0) {
            return undefined;
        }
        let items = _.map(l.selectedItems,
            function (o) {
                let tr;
                // console.log("CorrelationPlotComponent:inner building of query")
                // console.log("To convert:",o,"with key",vkey,"which yields",o[vkey])
                tr = qt[lname].parser(o[vkey])
                // console.log("parser responded", tr)
                return tr
            }
        )
        items.length === 1 ? items = items[0] : _.noop()
        r = { [queryTemplate[lname].fieldName]: items }
        // console.log("CorrelationPlotComponent:Outer built query of")
        // console.log(r)
        return r
    })
    // console.log("CorrelationPlotComponent:First list of selected items:")
    // console.log(ret)
    if (_.isEmpty(ret)) {
        console.log("CorrelationPlotComponent: construct query No selectedItemsFound. Returning defaults")
        return defaultQuery
    }
    else {
        // console.log("CorrelationPlotComponent:Non default Search Parameters found")
        // console.log("CorrelationPlotComponent:Default query")
        // console.log(defaultQuery)
        // console.log("CorrelationPlotComponent:Will merge with")
        // console.log(ret)
        // console.log("CorrelationPlotComponent:BY")
        // console.log('ret = _(defaultQuery).merge(_(ret).values().reduce()).value()')
        // console.log(_.values(ret))
        // console.log(_.reduce(_.values(ret),(result,val,key)=>(result[key])))
        ret = _(ret).values().reduce(_.merge)
        // console.log("CorrelationPlotComponent:merged ret")
        // console.log(ret)
        ret = _.assign(defaultQuery, ret)
        console.log("CorrelationPlotComponent:Returning overwriting query", ret)
        return {...ret}
    }

}


const mapDispatchToProps = (dispatch, ownProps) => {
    // console.log("CorrelationPlotComponent:Map dispatch to props ownprops")

    return {
        getCountriesForYear: (query) => {
            dispatch(requestCountriesByYearHarvIMF(query.year)).
                then(function (response) {
                    dispatch(receiveAvailableCountriesYearHarvIMF(response.payload.data))
                })
        },
        getSectorsForCountryYear: (query) => {
            dispatch(requestSectorsHarvIMF(query.country, query.year)).
                then(function (response) {
                    dispatch(receiveAvailableSectorsCountryYearHarvIMF(response.payload.data))
                })
        },
        requestPlotForCurrentQuery: (params) => {
            dispatch(requestPlot("CorrelationPlotComponent", { ...params }))
        },
        requestPlot: (params = false) => {
            dispatch(requestPlot("CorrelationPlotComponent", params))
        }
    }
}


class CorrelationPlotComponent extends React.Component {

    componentDidMount() {
        // console.log("CorrelationPlotComponent:CorrelationPlotComponent did mount")
        this.props.getCountriesForYear(this.searchParameters())
        // console.log("CorrelationPlotComponent:CorrelationPlotComponent did get countries")
        this.props.getSectorsForCountryYear(this.searchParameters())
        this.props.requestPlot(this.searchParameters())
        // console.log("CorrelationPlotComponent:CorrelationPlotComponent did get search parameters and requested plot")

    }
    onChange(selector_id, selections) {
       
        
        return selections
    }
    searchParameters() {
        return constructQuery(sels.getOrFalse(this.props,"simpleListsSelections",sels.getOrFalse(this.props,"defaultQuery")))
    }

    logprops() {
        console.log("CorrelationPlotComponent:Logging props")
        console.log(this.props)
    }
    componentDidUpdate(prevprops, prevstate) {
       console.log("CorrelationPlotComponent: Component did update", prevprops.currentQuery, this.props.currentQuery)
        if (!_.isEqual(prevprops.currentQuery, this.props.currentQuery)) {
            // console.log("Launch of plot request because query changed")
            // console.log("previous", prevprops.currentQuery, "now", this.props.currentQuery)
            this.props.requestPlot(this.props.currentQuery)
            this.props.onQueryChanged(this.props.currentQuery)
            let currentYear = sels.getFieldFromCurrentQuery(this.props, "year")
            if (currentYear) {
                if (sels.getFieldFromCurrentQuery(prevprops, "year") !== currentYear) {
                    this.props.getCountriesForYear(sels.getCurrentQueryFromProps(this.props))
                    this.props.getSectorsForCountryYear(sels.getCurrentQueryFromProps(this.props))
                }
            }
            let currentCountry = sels.getFieldFromCurrentQuery(this.props, "country")
            if (currentCountry) {
                if (sels.getFieldFromCurrentQuery(prevprops, "country") !== currentCountry) {
                    this.props.getSectorsForCountryYear(sels.getCurrentQueryFromProps(this.props))
                }
            }
            // let currentSectors = sels.getFieldFromCurrentQuery(this.props,"sectors")
            // if(currentSectors) {
            //     if(!_.isEqual(
            //         sels.getFieldFromCurrentQuery(prevprops,"sectors"),
            //         sels.getFieldFromCurrentQuery(this.props,"sectors")
            //         )){

            //         }
            // }
        }
    }
    render = () => {
        return (
            <Container className={["mt-3"]}>
            <Row className={["mb-2"]}>
                <Col>
                    <SimpleListTypeAhead
                        selectorUID="YEAR_FOR_CORR"
                        listKey="all_years_harv_imf"
                        labelKey="name"
                        valueKey="value"
                        placeholder="Years"
                        // defaultSelected={['2007']}
                        multiple={false}
                        onChange={(selectorId, selections) => { this.onChange(selectorId, selections) }}
                        currentlySelected={this.props.currentQuery.year}>

                    </SimpleListTypeAhead>
                </Col>
                <Col>
                    <SimpleListTypeAhead
                        selectorUID="COUNTRIES_FOR_YEAR"
                        listKey="oas_countries_harv_imf"
                        labelKey="name"
                        valueKey="value"
                        placeholder="Country"
                        // defaultSelected={['PRY']}
                        multiple={false}
                        onChange={(selectorId, selections) => { this.onChange(selectorId, selections) }}
                        currentlySelected={this.props.currentQuery.country}
                        >
            </SimpleListTypeAhead>
                </Col>
               
            </Row>
            <Row className={["mb-2"]}>
                    <Col>
                        <SimpleListTypeAhead
                            selectorUID="SECTOR_FOR_CORR"
                            listKey="all_sectors"
                            labelKey="sector_name"
                            valueKey="sector_code"
                            placeholder="Sectors"
                            multiple={true}
                            // defaultSelected={["Food and live animals"]}
                            onChange={(selectorId, selections) => { this.onChange(selectorId, selections) }}
                            currentlySelected={this.props.currentQuery.sectors}
                            >
                            >
            </SimpleListTypeAhead>
                    </Col>
            </Row>
            <Row className="mt-3">
                {/* <div>Currently rendering this data: {_(constructQuery(this.props)).map((o) => _.toString(o)).join(" ,")}</div>
                <div>And we have this props: {_({ ...this.props.currentQuery }).map((o) => _.toString(o)).join(" ,")} </div> */}
               <Col> <RemotePlotRedux plotKey="CorrelationPlotComponent" /></Col>
            </Row>
            </Container>
          
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CorrelationPlotComponent)
