import React from "react";
import Layout from "../components/UI/Layout/Layout"
// import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
// import Sidebar from "../components/UI/Sidebar/Sidebar"
// import Split from "../components/UI/ContentArea/Split/Split"
// import FirstPlot from "../components/Plots/FirstPlot/Firstplot"
import InfoAvailabilityDoc from "../content/markdown-pages/infoavailability.mdx"
// import TheDataSets from "../content/decks/TheDataSets.mdx"
// import WorldPlot from "../components/Plots/WorldPlot/Worldplot.js"
// import TheAmericasPlot from "../components/Plots/Theamericas/TheAmericas.js"
// import DataAvailabilityPlot from "../components/Plots/Capabilities/YearAvailabilityByCategory.js"
export default() =>  {
  return (
    <Layout>
      <InfoAvailabilityDoc/>
    </Layout>
      );
}
