import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import GenericPlot from "../../components/Plots/GenericPlot.js";
import YearAvailabilityData from "../../data/plots/capabilities/plot_year_coverage.json";
import YearAvailabilityBarsData from "../../data/plots/capabilities/plot_years_vs_ammount_of_countries_per_category.json";
import CountryYearScaleOf8Data from "../../data/plots/capabilities/countries_heatmap_scale_of_8.json";
import TestFromOpenCPU from "../../data/plots/testingopencpu.json";
export const _frontmatter = {
  "title": "Data availability",
  "path": "/data-availability",
  "date": "2019-01-29T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Data Availability`}</h1>
    <p>{`As discussed in the Three Datasets section, two of the datasets used by the Atlas have
their own research and exporation tools. `}</p>
    <p>{`The third database we explore, the main basis for the findings of the  `}<strong parentName="p">{`Signs of Competitiveness`}</strong>{`
research paper showcased here, is the `}<em parentName="p">{`Capabilities`}</em>{` dataset.`}</p>
    <h3>{`The Capabilities Dataset`}</h3>
    <h4>{`8 information categories from different datasets`}</h4>
    <p>{`This dataset was constructed by the authors of the `}<strong parentName="p">{`Signs of Competitiveness`}</strong>{` paper, and encompasses
in one document, side by side, a number of variables. These are categorized by capability area as follows: `}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Capacities`}</strong>{`, with the following variables:`}<ul parentName="li">
          <li parentName="ul">{`Infrastructure`}</li>
          <li parentName="ul">{`Macroeconomic environment`}</li>
          <li parentName="ul">{`Health and primary education`}</li>
          <li parentName="ul">{`Higher education and training`}</li>
          <li parentName="ul">{`Efficiency of the goods market`}</li>
          <li parentName="ul">{`Efficiency of the labor market`}</li>
          <li parentName="ul">{`Development of the financial market`}</li>
          <li parentName="ul">{`Technological preparation`}</li>
          <li parentName="ul">{`Market size`}</li>
          <li parentName="ul">{`Business sophistication`}</li>
          <li parentName="ul">{`Innovation`}</li>
        </ul></li>
    </ol>
    <p>{`The source of the information is the Global Competitiveness Index of the World
Economic Forum and in its raw form is `}<a parentName="p" {...{
        "href": "http://reports.weforum.org/global-competitiveness-index-2017-2018/#topic=data"
      }}>{`available here`}</a></p>
    <p>{`Our capabilities database includes the subset for the years 2007-2016.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Education`}</strong><ul parentName="li">
          <li parentName="ul">{`Years of schooling`}</li>
          <li parentName="ul">{`PISA test results in mathematics`}</li>
        </ul></li>
    </ol>
    <p>{`The source of information for the years of schooling is the United Nations
Development Program (UNDP) and for the PISA test, it is available from the OECD. Only the
information for the years 2003, 2006, 2009, 2012, 2015 is available.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Institutions`}</strong><ul parentName="li">
          <li parentName="ul">{`Property rights `}</li>
          <li parentName="ul">{`Intellectual property protection`}</li>
          <li parentName="ul">{`Diversion of public funds`}</li>
          <li parentName="ul">{`Public trust in politicians `}</li>
          <li parentName="ul">{`Irregular payments and bribes`}</li>
          <li parentName="ul">{`Judicial Independence`}</li>
          <li parentName="ul">{`Favoritism in decisions of government officials`}</li>
          <li parentName="ul">{`Efficiency of government spending`}</li>
          <li parentName="ul">{`Burden of government regulation`}</li>
          <li parentName="ul">{`Efficiency of legal framework in settling disputes`}</li>
          <li parentName="ul">{`Efficiency of legal framework in challenging regs. `}</li>
          <li parentName="ul">{`Transparency of government policymaking`}</li>
          <li parentName="ul">{`Business costs of terrorism`}</li>
          <li parentName="ul">{`Business costs of crime and violence`}</li>
          <li parentName="ul">{`Organized crime`}</li>
          <li parentName="ul">{`Reliability of police services`}</li>
          <li parentName="ul">{`Ethical behavior of firms`}</li>
          <li parentName="ul">{`Strength of auditing and reporting standards`}</li>
          <li parentName="ul">{`Efficacy of corporate boards`}</li>
          <li parentName="ul">{`Protection of minority shareholders’ interests`}</li>
          <li parentName="ul">{`Strength of investor protection. `}</li>
        </ul></li>
    </ol>
    <p>{`The source of the information is the Global Competitiveness Index of the World Economic Forum cited above.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><strong parentName="li">{`Investment`}</strong><ul parentName="li">
          <li parentName="ul">{`Investment as a percentage of GDP`}</li>
          <li parentName="ul">{`Foreign Direct Investment as a percentage of GDP`}</li>
        </ul></li>
    </ol>
    <p>{`The source of the information is The World Bank.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Macroeconomical`}</strong><ul parentName="li">
          <li parentName="ul">{`GDP Per Capita (PPP 2011)`}</li>
          <li parentName="ul">{`Gini Coefficient`}</li>
        </ul></li>
    </ol>
    <p>{`Also from The World Bank`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`Cultural dimensions of Hoefstede`}</strong><ul parentName="li">
          <li parentName="ul">{`Power Distance`}</li>
          <li parentName="ul">{`Individualism (vs. Collectivism)`}</li>
          <li parentName="ul">{`Uncertainty Avoidance`}</li>
          <li parentName="ul">{`Masculinity (vs. Femininity)`}</li>
          <li parentName="ul">{`Long/Short-term Orientation`}</li>
          <li parentName="ul">{`Indulgence. `}</li>
        </ul></li>
    </ol>
    <p>{`The source of the information is the Scores for the Hoefstede of IBM study (2010). The information is only
available for the year 2010.`}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">
        <p parentName="li">{`The `}<strong parentName="p">{`economic complexity index`}</strong>{` (ECI) from The Atlas of EconmicComplexity datasets cited above.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`ECI`}</li>
          <li parentName="ul">{`PCI`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Quality Of Exports`}</strong></p>
      </li>
    </ol>
    <p>{`From the Hein Quality Index`}</p>
    <h4>{`Information Availability`}</h4>
    <p>{`As you can see, not all of the variables included in the capabilities database area available for all years.
Furthermore, not all categories are available for every country and, for some countries, some variables in
some categories are available, while some are not for a given year.`}</p>
    <p>{`The following plot shows what percentage of variables are available per category, per year in the capabilities
dataset:`}</p>
    <GenericPlot jsonData={YearAvailabilityData} mdxType="GenericPlot"> </GenericPlot>
    <p>{`This, however, is not sufficent to get an idea of information availability.`}</p>
    <p>{`The following plot shows you how many countries, per category, have at least one
variable in a given year. For your information, the full number of countries
in the database, that is, member state of the OAS for which we have reported data,
is 27.`}</p>
    <GenericPlot jsonData={YearAvailabilityBarsData} mdxType="GenericPlot"> </GenericPlot>
    <p>{`As is immediatly evident, and please click on the category names to verify this,
there are good, very full years for each category, and then there are those
that dont do so well.`}</p>
    <p>{`That is why we have designed the exploration system for the capabilities dataset
for you to first pick a country, then pick a year, after that, we will pull the relevant
information datapoints available at that intersection and present you with a plot.`}</p>
    <p>{`As to which years are the best for which countries, consider the following heatmap.
Here we tell you how many information categories per year per country have at least
one data point. For a given country/year, a maximum of 8 categories might be
present.`}</p>
    <GenericPlot jsonData={CountryYearScaleOf8Data} mdxType="GenericPlot"> </GenericPlot>
    <p>{`It is immediatly evident that the best years for analysis by this dataset, are
2007-2016 for most, but not all countries.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      