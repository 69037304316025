import React,{useState} from "react";
import Sidebar from '../components/UI/Sidebar/Sidebar'
import Layout from "../components/UI/Layout/Layout"
import Split from "../components/UI/ContentArea/Split/Split"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import CorrelationPlotComponent from "../components/Plots/CorrelationPlotComponent/CorrelationPlotComponent.js"
import CapabilitiesDatasetDoc from "../content/markdown-pages/capabilities-dataset.mdx"
import ThreeDatasetsDoc from "../content/markdown-pages/three-datasets.mdx"
import SignsOfCompetitivenessDoc from '../content/markdown-pages/signs-of-competitive-research.mdx'
import Button from 'react-bootstrap/Button'
import {dispatchResize} from "../pollyfills"
import DataTableRedux from "../components/data/DataTableRedux.js"
import Container from "react-bootstrap/Container"
import _ from 'lodash'
export default() =>  {
  const [request,requestContent]=useState("")
  const [query,queryChanged] = useState({})
  const [defaultQuery, defaultQueryChanged] = useState({
    year: 2007,
    country: "PRY",
    sectors: [0,1,2,5,6,8]
  })
  const renderFor=(req) => {
    switch (req) {
      case "CAPABILITIES-DATASET-DOC":
        return(
          <CapabilitiesDatasetDoc></CapabilitiesDatasetDoc>
        )
      case "DATASETS-DISCUSSION":
        return(<ThreeDatasetsDoc></ThreeDatasetsDoc>)
      case "RESEARCH":
        return("Research render")
    }
  }
  return (
    <Layout>
      {/* <Split left={<Sidebar requestContent={requestContent}></Sidebar>} right={renderFor(request)}/>
      <DataAvailabilityPlot/> */}
      <Tab.Container id="left-tabs-example" defaultActiveKey="three-datasets">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="three-datasets">Three Datasets</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="capabilities-dataset">The Capabilities Dataset</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="cross-dataset-demo">Potential of cross-dataset exploration (demo)</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="signs-of-compet-research">Signs of Competitiveness Research Results</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="three-datasets" className={["mdx-div-container"]}>
                <ThreeDatasetsDoc></ThreeDatasetsDoc>
              </Tab.Pane>
              {/* TODO: Make a promise version of dispatchResize */}
              <Tab.Pane eventKey="capabilities-dataset" onEnter={() => dispatchResize()} className={["mdx-div-container"]}>
                <CapabilitiesDatasetDoc></CapabilitiesDatasetDoc>
              </Tab.Pane>
              <Tab.Pane eventKey="cross-dataset-demo" onEnter={() => dispatchResize()} >
                <Tabs defaultActiveKey="correlation-plot" id="the-demo">
                  <Tab eventKey="correlation-plot" title="The correlation Plot" className={['m-2', "mdx-div-container"]} onEnter={() => dispatchResize()} >
                    <h3>The IMF Quality vs. Export Data Correlations plot</h3>
                    This plot allows you to explore Pearson correlations of data from the Atlas of Economic Complexity Dataset, as they relate to the IMF Quality Index.
                    By default, we show you Paraguay's correlations for the year 2007. But you can choose the year, the country and even a set of sectors to aggregate the export data and quality index, and see if each of the variables have a positive or negative relationship.
                    <CorrelationPlotComponent 
                    
                    onQueryChanged={(query)=>{queryChanged(query)}} 
                    
                    defaultQuery={defaultQuery}>

                    </CorrelationPlotComponent>
                  </Tab>
                  <Tab eventKey="correlation-plot-data" title="Data">
                    <DataTableRedux plotKey="CorrelationPlotComponent" query={_.isEqual({},query)?defaultQuery:query}></DataTableRedux>
                  </Tab>
                </Tabs>
              </Tab.Pane>
              <Tab.Pane eventKey="signs-of-compet-research">
                <Container className={["mdx-div-container"]}>
                <SignsOfCompetitivenessDoc></SignsOfCompetitivenessDoc>
              </Container>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Layout>
      );
}
