import { createStore as reduxCreateStore,applyMiddleware} from "redux"
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import rootReducer from "./reducers.js"

const client = axios.create({ //all axios can be used, shown in axios documentation
  // baseURL:'https://www.tldr.mx/ocpu/'
  baseURL: 'https://www.tldr.mx/ocpu/'
});

const middlewareConfig = {
   interceptors: {
     request: [{
       success: function ({getState, dispatch, getSourceAction}, req) {
        //  console.log("DEBUG:MIDDLEWARE:request:success")
        //  console.log(req); //contains information about request object
         //..
         return req;
       },
       error: function ({getState, dispatch, getSourceAction}, error) {
         //...
        //  console.log("DEBUG:MIDDLEWARE:request:error")
         return "an error occurred"+error
       }
     }
     ],
     response: [{
       success: function ({getState, dispatch, getSourceAction}, res) {
        //  console.log("DEBUG:MIDDLEWARE:response:success")
        //  console.log(res); //contains information about request object
        //  //...
         return Promise.resolve(res);
       },
       error: function ({getState, dispatch, getSourceAction}, error) {
        //  console.log("DEBUG:MIDDLEWARE:response:error")
         return Promise.reject(error);
       }
     }
     ]
   }
 };

// const reducer = (state, action) => {
//   if (action.type === `INCREMENT`) {
//     return Object.assign({}, state, {
//       count: state.count + 1,
//     })
//   }
//   return state
// }

const initialState = { count: 0 }

// const createStore = () => reduxCreateStore(reducer, initialState)
let createStore = () => reduxCreateStore(
  rootReducer, //custom reducers
  applyMiddleware(
    //all middlewares
    axiosMiddleware(client,middlewareConfig), //second parameter options can optionally contain onSuccess, onError, onComplete, successSuffix, errorSuffix
  )
)
export default createStore
