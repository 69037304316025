import _ from 'lodash'

export const all_sectors =[
  {id:0,"sector_code":0,"sector_name":"Food and live animals"},
  {id:1,"sector_code":1,"sector_name":"Beverages and tobacco"},
  {id:2,"sector_code":2,"sector_name":"Raw materials"},
  {id:3,"sector_code":3,"sector_name":"Mineral fuels, lubricants and related materials"},
  {id:4,"sector_code":4,"sector_name":"Animal and vegetable oils, fats and waxes"},
  {id:5,"sector_code":5,"sector_name":"Chemicals"},
  {id:6,"sector_code":6,"sector_name":"Manufactured goods by material"},
  {id:7,"sector_code":7,"sector_name":"Machinery and transport equipment"},
  {id:8,"sector_code":8,"sector_name":"Micellanous manufactured articles"},
  {id:9,"sector_code":9,"sector_name":"Micellanous"}]

export const all_years_harv_imf = [
                                    {id:0,name:"2000",value:"2000"}, 
                                    {id:1,name:"2001",value:"2001"},
                                    {id:2,name:"2002",value:"2002"},
                                    {id:3,name:"2003",value:"2003"},
                                    {id:4,name:"2004",value:"2004"},
                                    {id:5,name:"2005",value:"2005"},
                                    {id:6,name:"2006",value:"2006"},
                                    {id:7,name:"2007",value:"2007"},
                                    {id:8,name:"2008",value:"2008"},
                                    {id:9,name:"2009",value:"2009"} ,
                                    {id:10,name:"2010",value:"2010"},
                                    {id:11,name:"2011",value:"2011"},
                                    {id:12,name:"2012",value:"2012"},
                                    {id:13,name:"2013",value:"2013"},
                                    {id:14,name:"2014",value:"2014"}
                                  ]

  

export const all_countries_harv_imf = [
                                        {
                                          id:0, 
                                          name: "Colombia",
                                          value: "COL"
                                        },
                                        {
                                          id:1, 
                                          name: "Dominican Republic",
                                          value: "DOM"
                                        },
                                        {
                                          id:2, 
                                          name: "Guatemala",
                                          value: "GTM"
                                        },
                                        {
                                          id:3, 
                                          name: "Trinidad & Tobago",
                                          value: "TTO"
                                        },
                                        {
                                          id:4, 
                                          name: "St. Kitts & Nevis",
                                          value: "KNA"
                                        },
                                        {
                                          id:5, 
                                          name: "Cuba",
                                          value: "CUB"
                                        },
                                        {
                                          id:6, 
                                          name: "Panama",
                                          value: "PAN"
                                        },
                                        {
                                          id:7, 
                                          name: "St. Lucia",
                                          value: "LCA"
                                        },
                                        {
                                          id:8, 
                                          name: "Nicaragua",
                                          value: "NIC"
                                        },
                                        {
                                          id:9, 
                                          name: "Dominica",
                                          value: "DMA"
                                        },
                                        {
                                          id:10, 
                                          name: "Uruguay",
                                          value: "URY"
                                        },
                                        {
                                          id:11, 
                                          name: "Jamaica",
                                          value: "JAM"
                                        },
                                        {
                                          id:12, 
                                          name: "St. Vincent & Grenadines",
                                          value: "VCT"
                                        },
                                        {
                                          id:13, 
                                          name: "Brazil",
                                          value: "BRA"
                                        },
                                        {
                                          id:14, 
                                          name: "Grenada",
                                          value: "GRD"
                                        },
                                        {
                                          id:15, 
                                          name: "Guyana",
                                          value: "GUY"
                                        },
                                        {
                                          id:16, 
                                          name: "Canada",
                                          value: "CAN"
                                        },
                                        {
                                          id:17, 
                                          name: "United States",
                                          value: "USA"
                                        },
                                        {
                                          id:18, 
                                          name: "Chile",
                                          value: "CHL"
                                        },
                                        {
                                          id:19, 
                                          name: "Bahamas",
                                          value: "BHS"
                                        },
                                        {
                                          id:20, 
                                          name: "Suriname",
                                          value: "SUR"
                                        },
                                        {
                                          id:21, 
                                          name: "Venezuela",
                                          value: "VEN"
                                        },
                                        {
                                          id:22, 
                                          name: "Antigua & Barbuda",
                                          value: "ATG"
                                        },
                                        {
                                          id:23, 
                                          name: "Haiti",
                                          value: "HTI"
                                        },
                                        {
                                          id:24, 
                                          name: "Bolivia",
                                          value: "BOL"
                                        },
                                        {
                                          id:25, 
                                          name: "Ecuador",
                                          value: "ECU"
                                        },
                                        {
                                          id:26, 
                                          name: "Belize",
                                          value: "BLZ"
                                        },
                                        {
                                          id:27, 
                                          name: "Barbados",
                                          value: "BRB"
                                        },
                                        {
                                          id:28, 
                                          name: "Mexico",
                                          value: "MEX"
                                        },
                                        {
                                          id:29, 
                                          name: "Argentina",
                                          value: "ARG"
                                        },
                                        {
                                          id:30, 
                                          name: "El Salvador",
                                          value: "SLV"
                                        },
                                        {
                                          id:31, 
                                          name: "Peru",
                                          value: "PER"
                                        },
                                        {
                                          id:32, 
                                          name: "Costa Rica",
                                          value: "CRI"
                                        },
                                        {
                                          id:33, 
                                          name: "Honduras",
                                          value: "HND"
                                        },
                                        {
                                          id:34, 
                                          name: "Paraguay",
                                          value: "PRY"
                                        }
                                      ]
export const all_variables_cors = [
  {id:0,value:"qual",name:"Quality Index"},
  {id:1,value: "export_value",name: "Export Value"},
  {id:2,value:"import_value",name: "Import Value"},
  {id:3,value:"export_rca", name: "Competitive Advantage (RCA)"},
  {id:4,value:"pci", name:"Product Complexity"},
  {id:5,value:"sector_name",name:"Sector"},
  {id:6,value:"product_name",name:"Product name (IMF)"}
]
export const country_code_name_map = _(all_countries_harv_imf).
                                        keyBy("value").
                                        mapValues("name").
                                        value()
export const sector_code_name_map = _(all_sectors).
                                      keyBy("sector_code").
                                      mapValues("sector_name").
                                      value()
export const cors_variable_name_map = _(all_variables_cors).
                                      keyBy("value").
                                      mapValues("name").
                                      value()

// console.log("INITIALIZING: ",country_code_name_map)