import React from "react";
import {testDo} from "../state/actions.js"
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'

//A mapper for the redux store
const mapStateToProps = (state,myprops) => {
console.log("MAPPPP State To Props")
console.log("state")
console.log(state)
// console.log("props")
// console.log(myprops)
// const {selectedSectors}=state
// return {selectedSectors:selectedSectors}
const d=state&&state.testData?state.testData:"nope";
return {testData:d}
}



class TestingMapStateToProps extends React.Component{
componentDidMount = () => {
  this.props.testDo("TESTING");
}
logprops = () => {
  console.log("Logging props")
  console.log(this.props)
}

handleTest = () => {
  this.props.testDo("<MOAR>TESTIES</MOAR>")
  // dispatch(testDo("DISPATCHEDTESTIE"))
}
     render= () =>  {
      return (
        <>
          <h1>Mapping state to props</h1>
          <button className="testing" onClick={this.handleTest}>
            Test
          </button>
        </>
          )

          }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ testDo }, dispatch)
}
export default connect(null,mapDispatchToProps) (TestingMapStateToProps)
