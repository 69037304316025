import React from "react"
import Layout from "../components/UI/Layout/Layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import oasLogo from '../data/images/oas-seal-vertical-slim.png'
import Carousel from 'react-bootstrap/Carousel'

// const carouselSlide () => {
//   return (
//     <Carousel.Item >

//     </Carousel.Item>
//   )
// }

export default() => {
return(
  <Layout>
    <Jumbotron className=" jumbotron-fluid justify-content-center" >
      <Carousel interval={null} className="p-5">
        <Carousel.Item>
      <Container>
        <Row className="text-center">
          <h1>Welcome to this preview of the Atlas of Competitive Intelligence for the Americas</h1>
        </Row>
            <Row className="align-items-center">
          <Col md={6}  >
            <blockquote className="blockquote text-center">
              This website will provide you with tools for discussion and exploration of the economic research results stemming from the <em>Signs of Competitiveness Report</em>
            </blockquote>
          </Col>
          <Col md={4} className="so-2">
            <Image src={oasLogo} rounded fluid />
          </Col>
        </Row>
      </Container>
        </Carousel.Item>
        <Carousel.Item >
          <Container>
          <h2 className={["display-4"]}>
             In this website, you will always be able to find the <em>Signs of Competitiveness Report</em> in your language, alongside the curated datasets its research is based on.
          </h2>
          </Container>
        </Carousel.Item>
        <Carousel.Item >
          <Container>
              <h2 className={["display-4"]}>It will include open exploration tools for the curated datasets. 
            </h2>
          </Container>
        </Carousel.Item>
        <Carousel.Item >
          <Container>
              <h2 className={["display-4"]}>To the best of our capacity, the data curation process will be reproducible and openly shared.
            </h2>
          </Container>
        </Carousel.Item>
        
        <Carousel.Item>
          <Container>
            <h2 className={["display-4"]}>Although our release date is still ahead, we are very excited to show you the potential of the Atlas.</h2>
          <br></br>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container>
          <h3 className={["display-4"]}>Head on to our <a href="/research">
              Research section </a> and: </h3>
              <br></br>
        <h3 className={["display-5"]}>
             <p>
               Get to know the datasets
             </p>
           
           	<p>Experience a demo exploration tool
             </p>
            <p>Review the results for the <em>Signs of Competitiveness</em> research
            paper.</p>
         
         </h3>
          </Container>
        </Carousel.Item>
      </Carousel>
    </Jumbotron>
  </Layout>
);
}
